import * as React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../../components/layout';
import Section from '../../components/section';
import ProductMeta from '../../components/products/ProductMeta';

import useProductsData from '../../hooks/use-products-data';

const pageTitle = 'Products';

const ProductCard = ({ node }) => {
  const { title, headline, tagline, filter, parent, deployments, logo, urls } =
    node.frontmatter;

  return (
    <div
      key={node.id}
      className={`p-4 text-gray-600  w-full md:w-1/2 dark:text-gray-400 product ${parent}`}
      data-filter={filter}
      data-parent={parent}
    >
      <Link to={`/products/${node.slug}`} className="animate-underline">
        <div className="flex lg:h-64 flex-col p-8 transition-all duration-700 ease-in-out transform bg-white rounded-lg dark:bg-gray-800 md:hover:scale-105 md:hover:shadow-lg sm:flex-row">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 mb-4 text-blue-500 bg-blue-100 rounded-full sm:mr-8 sm:mb-0">
            {logo && logo.publicURL ? (
              <img src={logo.publicURL} alt={title} />
            ) : (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-8 h-8"
                viewBox="0 0 24 24"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
              </svg>
            )}
          </div>
          {/* </Link> */}
          <div className="flex-grow text-gray-900 dark:text-gray-100">
            <h2 className="mb-3 text-lg font-medium title-font">{title}</h2>
            <ProductMeta
              parent={parent}
              deployments={deployments}
              migration={(urls && urls.migration) || null}
            />
            <p className="text-base leading-relaxed">{headline}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const ProductsListing = () => {
  const nodes = useProductsData();

  const parents = nodes.map((node) => node.frontmatter.parent);
  const uniqueParents = parents
    .filter((e, i) => parents.indexOf(e) === i)
    .sort();
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <script
          src={withPrefix('js/products-listing.js')}
          type="text/javascript"
        />
      </Helmet>
      <GatsbySeo
        language="en"
        title="Products • David Simpson Apps"
        description="Apps for monday.com and Atlassian Confluence & Jira that integrate with various external services."
        openGraph={{
          title: 'Products',
          url: 'https://www.dsapps.dev/products/',
          description:
            'Apps for monday.com and Atlassian Confluence & Jira that integrate with various external services.',
        }}
      />
      <Layout pageTitle={pageTitle}>
        <Section pageTitle={pageTitle} className="products-home">
          <div className="products-list" data-show-products="all">
            <div className="parent-selector">
              <Link
                to="#/"
                data-show-products="all"
                onClick={() => {
                  document
                    .querySelector('.products-list')
                    .setAttribute('data-show-products', 'all');
                }}
              >
                all
              </Link>

              <Link
                to="#/atlassian"
                data-filter="atlassian"
                onClick={() => {
                  document
                    .querySelector('.products-list')
                    .setAttribute('data-show-products', 'atlassian');
                }}
              >
                {' '}
                Atlassian{' '}
              </Link>
              {uniqueParents.map((parent) => (
                <Link
                  to={`#/${parent}`}
                  data-show-products={parent}
                  onClick={() => {
                    document
                      .querySelector('.products-list')
                      .setAttribute('data-show-products', parent);
                  }}
                >
                  {parent}
                </Link>
              ))}
            </div>

            <section
              className="body-font py-10 dark:text-white"
              id="product-introductions"
            >
              <div data-filter="atlassian" data-parent="atlassian">
                <h2 className="text-2xl">Apps for Atlassian</h2>

                <div className="mt-4 text-xl">
                  Powering innovation with better ways to manage your work.
                </div>
              </div>
              <div data-parent="confluence">
                <h2 className="text-2xl">Apps for Atlassian Confluence</h2>
                <div className="mt-4 text-xl">
                  Collaborate smarter by working with all your systems directly
                  inside of Confluence.
                </div>
              </div>
              <div data-parent="jira">
                <h2 className="text-2xl">
                  Apps for Atlassian Jira and Jira Service Management
                </h2>

                <div className="mt-4 text-xl">
                  Powering innovation with better ways to manage your work.
                </div>
              </div>
              <div data-filter="monday.com" data-parent="monday.com">
                <h2 className="text-2xl">Apps for monday.com</h2>
                <div className="mt-4 text-xl">
                  To help centralize all your work, processes, tools, and files
                  into the monday.com Work OS.
                </div>
              </div>
            </section>

            <section className="body-font">
              <div className="flex flex-wrap py-4 mx-auto">
                <div className="flex flex-wrap -m-4">
                  {nodes.map((node, i) => (
                    <ProductCard node={node} key={i} />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default ProductsListing;
