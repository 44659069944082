/* eslint-disable react/no-array-index-key */
import * as React from 'react';

const ProductMeta = ({ parent, deployments, migration, className = '' }) => (
  <div
    className={`product-meta my-4 text-xs lowercase iframed-hidden ${className} `}
    style={{ lineHeight: '2.5' }}
  >
    <a href={`/products/#/${parent}`} className="text-gray-600">
      <span className="product-parent rounded-full py-1 px-2 mr-1 bg-gray-200 dark:bg-gray-700 dark:text-gray-300">
        {parent}
      </span>
    </a>{' '}
    {deployments?.map((item, i) => (
      <span key={`prod-deploy-${i}`}>
        <span className="product-deployments rounded-full py-1 px-2 mr-1 bg-gray-200 dark:bg-gray-700 dark:text-gray-300">
          {item}
        </span>{' '}
      </span>
    ))}
    {migration && (
      <span
        className="product-migration rounded-full py-1 px-2 mr-1 bg-gray-200 dark:bg-gray-700 dark:text-gray-300"
        title="We provide support for your cloud migration journey."
      >
        Migration&nbsp;Assistance
      </span>
    )}
  </div>
);

export default ProductMeta;
